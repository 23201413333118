import React from 'react'
import { useStaticQuery, graphql, navigate } from 'gatsby'
import styled from 'styled-components'
import Img from 'gatsby-image'

import {
  H1,
  H3,
  CopyBody,
  CopyBodyH2,
  CopySubText,
  Button,
  Blue,
  Yellow,
  Purple,
} from './index'
import Nav from '../components/nav'
import SEO from '../components/seo'
import Footer from '../components/footer'
import Box from '../components/box'
import RowLayout from '../components/rowLayout'
import CompanyIcons from '../components/companyIcons'
import Hidden from '../components/hidden'
import HomePatternSvg from '../assets/svg/home/home-pattern-one.svg'
import useHotjarScript from '../utils/hooks/useHotjarScript'

const SlackPage = () => {
  const data = useStaticQuery(graphql`
    query {
      mirrorSlack: file(relativePath: { eq: "mirror-slack.png" }) {
        childImageSharp {
          fixed(width: 700, quality: 100) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      extractSignalsFromNoise: file(
        relativePath: { eq: "extract-signals-from-noise.png" }
      ) {
        childImageSharp {
          fixed(width: 480, quality: 100) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      linkMultipleChannels: file(
        relativePath: { eq: "slack-link-multiple-channels.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 331, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      selectSlackChannel: file(
        relativePath: { eq: "slack-select-slack-channel.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 331, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      selectStream: file(relativePath: { eq: "slack-select-stream.png" }) {
        childImageSharp {
          fluid(maxWidth: 331, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  React.useEffect(() => {
    window.location.replace('https://getthepulse.zendesk.com/hc/en-us/articles/360016996498-Slack-Integration')
  }, [])

  useHotjarScript()

  return (
    <Container>
      <SEO
        title="Streamline Pulse activities to your Slack channels - Pulse"
        description="Pair the quick and casual of Slack with the slow and thoughtful of Pulse. The effectiveness of real-time conversations, and the flexibility of asynchronous interactions."
        ogImage="/assets/ogimages/pulse-slack-integration.png"
        twImage="/assets/ogimages/twitter/pulse-slack-integration.png"
      />
      <Nav />
      <HomePatternSvg
        style={{
          width: '100%',
          height: '100%',
          position: 'absolute',
          top: 0,
          zIndex: 1,
        }}
      />
      <Banner>
        <RowLayout>
          <Box style={{ zIndex: 2, position: 'relative' }}>
            <p id="pulse-slack">Pulse + Slack</p>
            <H1 style={{ width: '45rem', maxWidth: '100%' }}>
              Streamline updates to Slack channels
            </H1>
            <Button onClick={() => navigate('/signup')}>
              Get Pulse For Free
            </Button>
            <p id="hero-subtext">Create your workspace in {'<'}2min</p>
            <CopyBodyH2
              style={{
                marginTop: '2rem',
                marginBottom: '2rem',
                width: '95%',
              }}
            >
              Pair the <Blue>quick and casual of Slack</Blue> with the{' '}
              <Purple>slow and thoughtful of Pulse</Purple>. The effectiveness
              of real-time conversations, and the flexibility of slower,{' '}
              <Yellow>high-value asynchronous interactions.</Yellow>
            </CopyBodyH2>
            <Hidden widthDown={600}>
              <CompanyIcons />
            </Hidden>
          </Box>
        </RowLayout>
      </Banner>
      <RowLayout id="mirror-key-activities">
        <Box style={{ flex: 1.5, paddingTop: '2rem' }}>
          <H3>Mirror key activities on Slack with one click.</H3>
          <CopySubText>
            Meet your team members where they already are. Get notifications in
            your team's Slack channel anytime a new update is shared or a key
            event takes place in one of your streams.
          </CopySubText>
          <SlackList>
            <Box className="copy-list">
              <Box className="copy-section">
                <h3 className="copy-header">&gt; Amplify your messages</h3>
                <p className="copy-body">
                  Automatically route Pulse updates to Slack channels to notify
                  your people where they already are.
                </p>
              </Box>
              <Box className="copy-section">
                <h3 className="copy-header">&gt; Search Pulse from Slack</h3>
                <p className="copy-body">
                  Search across your Pulse updates directly from Slack.
                </p>
              </Box>
            </Box>
            <Box style={{ flex: 1 }}>
              <Box className="copy-section">
                <h3 className="copy-header">&gt; Beautiful, enriched links</h3>
                <p className="copy-body">
                  When a Pulse link is spotted, our Slack bot crawls it and
                  provides a simple, beautiful preview.
                </p>
              </Box>
              <Box className="copy-section">
                <h3 className="copy-header">
                  &gt; Create pulses from messages
                </h3>
                <p className="copy-body">
                  Easily create drafts in Pulse out of Slack messages.
                </p>
              </Box>
            </Box>
          </SlackList>
        </Box>
        <Hidden widthDown={400}>
          <Box
            style={{
              flex: 1,
              display: 'flex',
              justifyContent: 'flex-end',
            }}
          >
            <Img
              fixed={data.mirrorSlack.childImageSharp.fixed}
              fadeIn={false}
            />
          </Box>
        </Hidden>
      </RowLayout>
      <RowLayout
        style={{
          paddingLeft: 0,
          display: 'flex',
          alignItems: 'center',
          paddingTop: '2rem',
        }}
      >
        <Box>
          <Hidden widthDown={600}>
            <Img
              fixed={data.extractSignalsFromNoise.childImageSharp.fixed}
              fadeIn={false}
            />
          </Hidden>
        </Box>
        <Box id="start-updates-messages">
          <H3>
            Start updates from messages.
            <br />
            Extract signals from noise.
          </H3>
          <CopySubText>
            When important updates or decisions get shared in Slack they easily
            disappear, without giving those who aren’t online the opportunity to
            catch up. Pulse helps you easily create updates from any message in
            your Slack channel to keep the updates organized, and make it easy
            for your team to catch up on it when they can.
          </CopySubText>
        </Box>
      </RowLayout>
      <RowLayout id="link-pulse-streams">
        <H3 style={{ width: '36.375rem', maxWidth: '100%' }}>
          Link Pulse Streams to Slack channels in seconds.
        </H3>
        <CopySubText>
          {' '}
          Pulse keeps everyone aligned and working more efficiently. Engineers,
          designers, and peers – all collaborating in one tool.
        </CopySubText>
        <LinkPulseStreams>
          <Box className="copy-section">
            <h3 className="copy-header">1. Select a Stream </h3>
            <CopySubText>
              First, go to the home page of the Pulse stream you want to connect
              to Slack.
            </CopySubText>
            <Img
              fluid={data.selectStream.childImageSharp.fluid}
              fadeIn={false}
            />
          </Box>
          <Box className="copy-section">
            <h3 className="copy-header">2. Select the Slack channel </h3>
            <CopySubText>
              Second, hit connect to Slack on the right side of your screen.
            </CopySubText>
            <Img
              fluid={data.selectSlackChannel.childImageSharp.fluid}
              fadeIn={false}
            />
          </Box>
          <Box className="copy-section">
            <h3 className="copy-header">3. Link to multiple channels </h3>
            <CopySubText>
              Third, connect your stream to one or multiple Slack channels.
            </CopySubText>
            <Img
              fluid={data.linkMultipleChannels.childImageSharp.fluid}
              fadeIn={false}
            />
          </Box>
        </LinkPulseStreams>
      </RowLayout>
      <Footer />
    </Container>
  )
}

const Container = styled.div`
  #mirror-key-activities {
    padding-top: 0;
    padding-right: 1rem;
    @media (min-width: 600px) {
      padding-top: 5rem;
      padding-right: 0rem;
    }
  }
  #start-updates-messages {
    flex: 1;
    padding-left: 1rem;
    @media (min-width: 600px) {
      padding-left: 5rem;
    }
  }
  #link-pulse-streams {
    padding-top: 3rem;
    padding-bottom: 3rem;
    flex-direction: column;
    @media (min-width: 600px) {
      padding-top: 7.5rem;
      padding-bottom: 10rem;
    }
  }
`

const Banner = styled.div`
  width: 100%;
  min-height: 40.75rem;
  max-height: max-content;
  background-color: black;
  background-size: cover;
  padding-bottom: 2rem;
  box-sizing: border-box;
  position: relative;
  padding-top: 4.375rem;

  #hero-subtext {
    font-size: 0.875rem;
    line-height: 1.125rem;
    color: rgba(255, 255, 255, 0.6);
    font-family: ${props => props.theme.typography.fontFamilyGTMono};

    a {
      color: rgba(255, 255, 255, 0.6);
    }
  }

  #pulse-slack {
    font-family: ${props => props.theme.typography.fontFamilyGTMono};
    font-size: 1rem;
    line-height: 1.75rem;
    color: rgba(255, 255, 255, 0.6);
    margin: 0;
    margin-bottom: 1.5rem;
  }
`

const SlackList = styled.section`
  display: flex;
  margin-top: 2rem;
  flex-direction: column;

  .copy-list {
    flex: 1;
    margin-right: 0;
  }

  .copy-section {
    margin-bottom: 2rem;
  }

  .copy-header {
    font-family: ${props => props.theme.typography.fontFamilyGTMono};
    font-style: normal;
    font-weight: normal;
    font-size: 1rem;
    line-height: 1.75rem;
    margin: 0;
    margin-bottom: 0.75rem;
    color: #d9a3ff;
  }

  .copy-body {
    width: 100%;
    font-style: normal;
    font-weight: normal;
    font-size: 1rem;
    line-height: 1.75rem;
    margin: 0;
    color: rgba(255, 255, 255, 0.9);
  }

  @media (min-width: 600px) {
    flex-direction: row;
    .copy-body {
      width: 18rem;
    }
    .copy-list {
      margin-right: 2rem;
    }
  }
`

const LinkPulseStreams = styled.section`
  display: flex;
  padding-top: 2rem;
  flex-wrap: wrap;
  flex-direction: column;

  .copy-section {
    margin-bottom: 2rem;
    flex: 1;
  }

  .copy-header {
    font-family: ${props => props.theme.typography.fontFamilyGTMono};
    font-style: normal;
    font-weight: normal;
    font-size: 1rem;
    line-height: 1.75rem;
    margin: 0;
    margin-bottom: 0.75rem;
    color: #f9e08c;
  }

  @media (min-width: 600px) {
    flex-direction: row;
    .copy-section {
      margin-right: 2rem;
    }
  }
`

export default SlackPage
